<template>
	<div>
		<b-row>
			<div class="filter-wrap">
				<div
					class="filter"
					v-bind:class="[showFiltersForm ? 'filter-active' : '']"
					@click="filterDropDownActive"
					title="Alterar Filtros"
				>
					<FilterIcon/>
				</div>
			</div>
		</b-row>
		<b-row class="pl-0 pr-0">
			<b-col class="pl-0 pr-0">
				<div v-if="showFiltersForm" class="filters-form content-card">
					<div>
						<b-row
							class="filters-orientation filters-section"
						>
							<b-col v-if="showSelect.grouping" class="filter-item">
								<label>Agrupamento</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									v-model="groupingSelected"
									:options="{ multi: false, labelName: 'nome' }"
									:selectOptions="grouping"
									data-cy="status"
									:btnLabel="() => (groupingSelected.length > 1 ? `${groupingSelected[0].nome}...` : groupingSelected[0] && groupingSelected[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col v-if="showSelect.category" class="filter-item">
								<label>Categorias</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									v-model="categorySelected"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="categorys"
									data-cy="status"
									:btnLabel="() => (categorySelected.length > 1 ? `${categorySelected[0].nome}...` : categorySelected[0] && categorySelected[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col v-if="showSelect.month" class="filter-item">
								<label>Meses</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									v-model="monthsSelected"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="months"
									data-cy="status"
									:btnLabel="() => (monthsSelected.length > 1 ? `${monthsSelected[0].nome}...` : monthsSelected[0] && monthsSelected[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col v-if="showSelect.year" class="filter-item">
								<label>Ano</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									v-model="yearsSelected"
									:options="{ multi: false, labelName: 'nome' }"
									:selectOptions="years"
									data-cy="status"
									:btnLabel="() => (yearsSelected.length > 1 ? `${yearsSelected[0].nome}...` : yearsSelected[0] && yearsSelected[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col v-if="showSelect.type" class="filter-item">
								<label>Tipo Gráfico</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									v-model="typeChartSelected"
									:options="{ multi: false, labelName: 'nome' }"
									:selectOptions="typeChart"
									data-cy="status"
									:btnLabel="() => (typeChartSelected.length > 1 ? `${typeChartSelected[0].nome}...` : typeChartSelected[0] && typeChartSelected[0].nome) || 'Selecione'"
								/>
							</b-col>
						</b-row>
						<div class="mt-4 search-button" @click="findTransactions()">
							<SearchIcon/>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import "@/assets/css/style.css";
	import { CategoryExpensesService } from "@/services/categoryExpenses";

	export default {
		props: {
			showSelect: {
				type: Object,
				require: false
			},
			defaultFilters: {
				type: Object,
				require: false
			}
		},

		data () {
			return {
				showFiltersForm: false,
				months: [
					{
						nome: "Janeiro",
						value: 1
					}, {
						nome: "Fevereiro",
						value: 2
					}, {
						nome: "Março",
						value: 3
					}, {
						nome: "Abril",
						value: 4
					}, {
						nome: "Maio",
						value: 5
					}, {
						nome: "Junho",
						value: 6
					}, {
						nome: "Julho",
						value: 7
					}, {
						nome: "Agosto",
						value: 8
					}, {
						nome: "Setembro",
						value: 9
					}, {
						nome: "Outubro",
						value: 10
					}, {
						nome: "Novembro",
						value: 11
					}, {
						nome: "Dezembro",
						value: 12
					}
				],
				monthsSelected: [],
				years: [],
				yearsSelected: [],
				grouping: [
					{
						nome: "Categoria",
						value: 1
					}, {
						nome: "Mes e Ano",
						value: 2
					}
				],
				groupingSelected: [],
				typeChart: [
					{
						nome: "Barra",
						value: "bar"
					}, {
						nome: "Linha",
						value: "line"
					}
				],
				typeChartSelected: [],
				categorySelected: [],
				categoryService: new CategoryExpensesService()
			};
		},

		computed: {

		},

		watch: {
		},

		async mounted () {
			const result = await this.categoryService.listCategoryExpenses({offset: 0, limit: null}) || {};
			this.categorys = result.rows?.map(item => ({ nome: item.description, value: item.idCategoryExpense })) || [];
			const lastYear = 2050;
			for (let year = 2020; year <= lastYear; year++) {
				this.years.push({
					nome: year.toString(),
					value: year.toString()
				});
			}
			const currentYear = ((new Date()).getFullYear()).toString();
			this.yearsSelected = [{
				nome: currentYear,
				value: currentYear
			}];

			this.groupingSelected = [{
				nome: "Categoria",
				value: 1
			}];

			this.typeChartSelected = this.defaultFilters?.type ? this.typeChart.filter(ele => ele.value === this.defaultFilters.type) :
				this.typeChartSelected = [{nome: "Barra", value: "bar"}];

			this.findTransactions();
		},

		methods: {
			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			findTransactions () {
				const filters = {
					monthsSelected: this.monthsSelected,
					yearsSelected: this.yearsSelected,
					groupingSelected: this.groupingSelected,
					typeChartSelected: this.typeChartSelected[0].value,
					categorySelected: this.categorySelected
				};
				this.$emit("findTransactions", filters);
			}
		}
	};
</script>

<style scoped>
	.style-select {
		color: #28A745;
		border-color: #28A745;
		font-family: "Roboto Condensed Regular";
		font-size: 0.875rem;
	}

	.select-options {
		min-width: 130px;
		max-width: 200px;
		width: 20%;
		margin-right: 5px;
	}

	.section-select {
		display: flex;
		align-items: center;
		background-color: aliceblue;
		padding-bottom: 1rem !important;
		padding: 0px 8px;
		margin-bottom: 0px;
		margin-left: 0px;
		width: 100%;
		justify-content: right;
	}
</style>

